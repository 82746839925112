nav {
    background: white;
    height: var(--nav-bar-height);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 2px 8px #d0d1d2;

    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}
  
nav a.unlink  {
    color: var(--dark-grey-color) !important;
}
  
nav .logo {
    margin: 0;
    background: none;
    border: 2px solid #525252;
    font-size: 14px;
    font-weight: 600;
    padding: 3px 6px;
    font-family: Ubuntu;
    color: var(--dark-grey-color) !important;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: 20px;
    transition: background 0.3s;
}
  
nav .logo:hover {
    background: #f0f1f2;
}
  
nav .link-holder {
    display: flex;
    align-items: center;
    margin-right: 20px;
}
  
nav .burger-button {
    font-size: 22px;
    margin-right: 10px;
    display: none;
    cursor: pointer;
    padding: 10px;
    box-sizing: content-box;
}
  
nav ul {
    list-style: none;
    margin: 0;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    padding: 0px;
}
  
nav ul li {
    margin: 0;
    white-space: nowrap;
    text-align: center;
    min-width: 72px;
    height: var(--nav-bar-height);
    line-height: 60px;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    font-size: 14px;
    color: #314659 !important;
    font-weight: 400;
    font-family: Avenir,-apple-system,BlinkMacSystemFont,'Segoe UI','PingFang SC','Hiragino Sans GB','Microsoft YaHei','Helvetica Neue',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol',sans-serif;
    transition: all 0.5s;
}
  
nav ul li.link {
    cursor: pointer;
}
  
nav ul li.link:hover, nav ul li.link.is-active {
    border-top: 2px solid #1890ff;
    border-bottom: 2px solid transparent;
}
  
nav ul li a {
    display: block;
    height: 60px;
    padding: 0 20px;
    color: #314659;
    text-decoration: none !important;
}
  
nav ul li.link:hover a, nav ul li.link.is-active a {
    color: #1890ff !important;
} 
  
nav .anticon-user {
    vertical-align: 0;
}
  
nav .logout-button {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
nav .logout-button span {
    margin-right: 5px;
}
  
nav .logout-button svg {
    font-size: 14px;
}
  
@media (max-width: 1000px) {
  
    nav .logo {
      margin-left: 0px;
    }
    nav .link-holder {
      margin-right: 0px;
    }
  
    nav .burger-button {
      display: block;
    }
  
    nav ul {
      list-style: none;
      margin: 0;
      flex-direction: column;
      background: white;
      position: absolute;
      top: 74px;
      right: 40px;
      box-shadow: 0 2px 8px #d0d1d2;
      border-radius: 3px;
      overflow: hidden;
      z-index: 1000;
      transition: all 0.5s;
      transform: translateX(5%) translateY(-64%) scale(0);
    }
  
    nav.is-open ul {
      transform: translateX(0%) translateY(0%) scale(1);
    }
  
    nav ul li {
      height: 50px;
      line-height: 50px;
      padding: 0px;
      min-width: 200px;
      border: 2px solid transparent !important;
    }
  
    nav ul li.link:hover, nav ul li.link.is-active {
      border-right: 2px solid #1890ff !important;
      background-color: #e6f7ff;
    }
  
    nav ul li:hover a {
      color: #314659 !important;
    }
  
    nav .logout-button {
      margin-left: 20px;
    }
  
    nav .logout-button span {
      display: none;
    }
  
}